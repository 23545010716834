/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import SchedulingGuard from 'src/components/SchedulingGuard';
import TrackingGuard from 'src/components/TrackingGuard';
import AccessGuard from 'src/components/AccessGuard';
import ExpiryGuard from './components/ExpiryGuard';


const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  // {
  //   exact: true,
  //   guard: GuestGuard,
  //   path: '/register',
  //   component: lazy(() => import('src/views/auth/RegisterView'))
  // },
  // {
  //   exact: true,
  //   path: '/register-unprotected',
  //   component: lazy(() => import('src/views/auth/RegisterView'))
  // },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/account" />
      },
      {
        exact: true,
        path: '/app/account',
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/admin-tracking',
        guard: TrackingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/management/ActivityListView'))
      },
      {
        exact: true,
        path: '/user-tracking',
        guard: TrackingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/management/ActivityListUser'))
      },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard',
      //   component: lazy(() => import('src/views/reports/DashboardView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard-alternative',
      //   component: lazy(() =>
      //     import('src/views/reports/DashboardAlternativeView')
      //   )
      // },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/users',
        guard: AccessGuard,
        expiry: ExpiryGuard,

        roles: ['admin', 'analyst_admin', "hospital_admin"],
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      // {
      //   exact: true,
      //   path: '/users/certificates',
      //   component: lazy(() => import('src/views/management/CustomerFilterView'))
      // },
      {
        exact: true,
        path: '/users/:customerId',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', "hospital_admin"],
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        )
      },
      {
        exact: true,
        path: '/user/:customerId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        guard: AccessGuard,
        expiry: ExpiryGuard,
        path: '/user/add',
        roles: ['admin', 'analyst_admin'],
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/certificates',
        component: () => <Redirect to="/certificates/cme" />
      },
      {
        exact: true,
        path: '/certificates/cme',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/CmeCertificateListView')
        )
      },
      {
        exact: true,
        path: '/certificates/regulatory',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/RegulatoryCertificateListView')
        )
      },
      {
        exact: true,
        path: '/certificates/reports',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/LicenseReport')
        )
      },
      {
        exact: true,
        path: '/certificates/task',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/CertificateTaskDashboard')
        )
      },
      {
        exact: true,
        path: '/certificateTask/:customerId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/CertificateTaskEdit'))
      },
      {
        exact: true,
        path: '/certificates/cronlog',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() =>
          import('src/views/management/CertificateCronLogView')
        )
      },
      {
        exact: true,
        path: '/addCertificate/:customerId/',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/CertificateAdd'))
      },
      {
        exact: true,
        path: '/credentialing',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/CredentialsView'))
      },
      {
        exact: true,
        path: '/editCredentials/:id/',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/EditCredentails'))
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/management/ProductListView'))
      },
      {
        exact: true,
        path: '/hospitals',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', "hospital_admin"],
        component: lazy(() => import('src/views/management/HospitalsListView'))
      },
      {
        exact: true,
        path: '/hospitals/:hospitalID',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', "hospital_admin"],
        component: lazy(() =>
          import('src/views/management/HospitalDetailsView')
        )
      },
      {
        exact: true,
        path: '/hospitals/:hospitalID/departments',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', "hospital_admin"],
        component: lazy(() =>
          import('src/views/management/DepartmentsListView')
        )
      },
      {
        exact: true,
        path: '/hospital/add',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/HospitalCreateView'))
      },
      {
        exact: true,
        path: "/hospital/:hospitalID/subscription",
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', "hospital_admin"],
        component: lazy(() => import("src/views/management/HospitalSubscriptionView"))
      },
      {
        exact: true,
        path: '/hospital/consent',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() =>
          import('src/views/management/HospitalConsentView')
        )
      },
      {
        exact: true,
        path: '/departments',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', "hospital_admin"],
        component: lazy(() =>
          import('src/views/management/DepartmentsListView')
        )
      },
      {
        exact: true,
        path: '/orders',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/OrderListView'))
      },

      {
        exact: true,
        path: '/orders/add',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/OrderCreateView'))
      },
      {
        exact: true,
        path: '/orders/:id',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/viewInvoiceDetail/:id',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'accounts'],
        component: lazy(() => import('src/views/management/ProductInvoiceDetailView'))
      },    
      {
        exact: true,
        path: '/viewInvoice',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'accounts'],
        component: lazy(() => import('src/views/management/ProductInvoiceListView'))
      },
      {
        exact: true,
        path: '/invoice/add',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'accounts'],
        component: lazy(() => import('src/views/management/AddProductInvoice'))
      },
      {
        exact: true,
        path: '/invoice/:invoiceId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['accounts'],
        component: lazy(() => import('src/views/management/ProductInvoiceEdit'))
      },
      {
        exact: true,
        path: '/viewMealsExpense',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'accounts'],
        component: lazy(() => import('src/views/management/MealsExpenseView'))
      },
      {
        exact: true,
        path: '/viewSiteExpense',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'accounts'],
        component: lazy(() => import('src/views/management/SiteExpenseView'))
      },
      {
        exact: true,
        path: '/viewTravelExpense',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'accounts'],
        component: lazy(() => import('src/views/management/TravelExpenseView'))
      },
      {
        exact: true,
        path: '/travelExpense/:expenseId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'accounts'],
        component: lazy(() => import('src/views/management/TravelExpenseEdit'))
      },
      {
        exact: true,
        path: '/mealsExpense/:expenseId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'accounts'],
        component: lazy(() => import('src/views/management/MealsExpenseEdit'))
      },
      {
        exact: true,
        path: '/siteExpense/:expenseId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'accounts'],
        component: lazy(() => import('src/views/management/SiteExpenseEdit'))
      },
      {
        exact: true,
        path: '/licenseRequest',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/ViewConsultation'))
      },
      {
        exact: true,
        path: '/journalRequest',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', null, 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/ViewJournal'))
      },
      {
        exact: true,
        path: '/invoices',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/ViewLicense'))
      },
      {
        exact: true,
        path: '/licenseRequest/:request_id/viewDetails',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/LicenseRequestView'))
      },
      {
        exact: true,
        path: '/journalRequest/:request_id/viewDetails',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', null, 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/JournalLicenseRequestView'))
      },

      {
        exact: true,
        path: '/licenseRequest/:request_id/addLeadDetails',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/LicenseRequestAdd'))
      },
      {
        exact: true,
        path: '/journalRequest/:request_id/addLeadDetails',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', null, 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/JournalLicenseRequestAdd'))
      },
      {
        exact: true,
        path: '/medtigoPlusRequest',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/ViewmedtigoPlusConsultation'))
      },

      {
        exact: true,
        path: '/progress',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/ProgressListView'))
      },
      {
        exact: false,
        path: '/department/add',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/DepartmentsCreateView')
        )
      },
      {
        exact: false,
        path: '/certificates/:certificateId/email/',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/CertificateSendEmailView')
        )
      },
      {
        exact: true,
        path: '/certificates/:customerId/edit',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/CertificateEditView')
        )
      },
      // {
      //   exact: true,
      //   path: '/certificateEdit/:customerId/',
      //   component: lazy(() => import('src/views/management/CertificateEditView'))
      // },
      {
        exact: true,
        path: '/certificateEdit/:customerId/',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/CertificateViewStatus'))
      },
      {
        exact: true,
        path: '/certificates/:customerId/addNotes',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/CertificateNotes'))
      },
      {
        exact: true,
        path: '/certificates/:customerId/viewNotes',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/CertificateViewNotes')
        )
      },
      {
        exact: true,
        path: '/certificates/:customerId/addLicenseCronNotes',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/LicenseCronLogNotes'))
      },
      {
        exact: true,
        path: '/certificates/:customerId/viewLicenseCronNotes',
        guard: AccessGuard,
        expiry: ExpiryGuard,

        roles: ['admin'],
        component: lazy(() =>
          import('src/views/management/LicenseCronLogViewNotes')
        )
      },
      
      {
        exact: true,
        path: '/certificates/:customerId/addTask',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() => import('src/views/management/CertificateTask'))
      },
      {
        exact: true,
        path: '/certificates/:customerId/viewTask',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin', 'analyst_admin', 'hospital_admin'],
        component: lazy(() =>
          import('src/views/management/CertificateViewTask')
        )
      },
      {
        exact: false,
        path: '/roles/assign',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/management/UserAssignRoles'))
      },
      {
        exact: true,
        path: '/schedules',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/scheduling/ScheduleListView'))
      },
      {
        exact: true,
        path: '/open/shifts',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/scheduling/OpenShifts'))
      },
      {
        exact: true,
        path: '/shifts',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/scheduling/ShiftListView'))
      },
      {
        exact: true,
        path: '/assigned-shifts',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/scheduling/UnassignShiftView'))
      },
      {
        exact: true,
        path: '/schedules/calendar',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/scheduling/ScheduleCalendar'))
      },
      {
        exact:true,
        path:'/schedules/dndcalendar',
        guard:SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(()=> import("src/views/scheduling/DndCalender"))
      },
      {
        exact:true,
        path:'/schedules/report',
        guard:SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(()=> import("src/views/scheduling/ScheduleReport"))
      },
      {
        exact:true,
        path:'/scheduleReport',
        guard:SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(()=> import("src/views/scheduling/ScheduleReport"))
      },
      {
        exact:true,
        path:'/schedule/:id',
        guard:SchedulingGuard,
        expiry: ExpiryGuard,
        component:lazy(()=> import("src/views/scheduling/ScheduleReport/userData"))
      },
      {
        exact: true,
        path: '/schedules/requests',
        guard: SchedulingGuard,
        expiry: ExpiryGuard,
        component: lazy(() => import('src/views/scheduling/ScheduleRequests'))
      },
      {
        exact: true,
        path: '/schedules/payment-requests',
        guard: SchedulingGuard,
        component: lazy(() => import('src/views/scheduling/PaymentRequests'))
      },
      {
        exact: true,
        path: '/schedules/payment-request-details/:id',
        guard: SchedulingGuard,
        component: lazy(() => import('src/views/scheduling/PaymentRequests/UserPaymentRequests'))
      },
      {
        exact: true,
        path: '/jobRequests',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/recruiter/JobsRequests'))
      },
      {
        exact: true,
        path: '/viewJobDetail/:id',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() => import('src/views/recruiter/JobRequestDetailView'))
      },  
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/management/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/management/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/management/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/management/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      // {
      //   exact: true,
      //   path: '/app/calendar',
      //   component: lazy(() => import('src/views/calendar/CalendarView'))
      // },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      // {
      //   exact: true,
      //   path: ['/app/chat/new', '/app/chat/:threadKey'],
      //   component: lazy(() => import('src/views/chat/ChatView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/chat',
      //   component: () => <Redirect to="/app/chat/new" />
      // },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/projects/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/projects/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/projects/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/projects/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/simulation/casesView',
        guard: AccessGuard,
        expiry: ExpiryGuard,
        roles: ['admin'],
        component: lazy(() =>
          import('src/views/management/SimulationCaseStatusView')
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Expiry = route.expiry || Fragment;
          const Guard = route.guard || FragmentWrapper;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Expiry>
                  <Guard roles={route.roles || []}>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                </Expiry>

              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function FragmentWrapper({children}){
  return <>{children}</>
}

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;